/*---------------------
  Footer
-----------------------*/
.footer {
  background: #02173c;
  padding-top: 70px;
  padding-bottom: 0;
}

.footer__about {
  margin-bottom: 30px;

  ul {

    li {
      font-size: 16px;
      color: $white-color;
      line-height: 36px;
      list-style: none;
    }
  }
}

.footer__about__logo {
  margin-bottom: 15px;
  border-radius: 10px;
  overflow: hidden;

  a {
    display: inline-block;
  }
}

.footer__widget {
  margin-bottom: 30px;
  overflow: hidden;

  h6 {
    color: $white-color;
    font-weight: 600;
    margin-bottom: 10px;
  }

  ul {
    width: 50%;
    float: left;

    li {
      list-style: none;

      a {
        color: $white-color;
        font-size: 14px;
        line-height: 32px;
      }
    }
  }

  p {
    font-size: 14px;
    color: $white-color;
    margin-bottom: 30px;
  }

  form {
    position: relative;
    margin-bottom: 30px;

    input {
      width: 100%;
      font-size: 16px;
      padding-left: 20px;
      color: $white-color;
      height: 46px;
      border: 1px solid #ededed;

      &::placeholder {
        color: $white-color;
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      padding: 0 26px;
      height: 100%;
    }
  }

  .footer__widget__social {

    a {
      display: inline-block;
      height: 41px;
      width: 41px;
      font-size: 16px;
      color: $primary-color;
      border: 1px solid $primary-color;
      border-radius: 50%;
      line-height: 38px;
      text-align: center;
      background: $white-color;
      @include transition(all, .3s);
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background: $primary-color;
        color: $white-color;
        border-color: $white-color;
      }
    }
  }
}

.footer__copyright {
  border-top: 1px solid $border;
  padding: 15px 0;
  color: $white-color;
  overflow: hidden;
  margin-top: 20px;
}

.footer__copyright__text {
  font-size: 12px;
  color: $white-color;
  float: left;
  line-height: 25px;
  p {
    font-size: 12px;
    color: $white-color;
  }
}

.footer__copyright__payment {
  float: right;
}