/*----------------------
    Slider
----------------------*/
.home__slider {

    .col-lg-12 {
        max-width: 100%;
    }

    &.owl-carousel {

        .owl-nav {

            button {
                font-size: 18px;
                color: $normal-color;
                height: 70px;
                width: 30px;
                line-height: 70px;
                text-align: center;
                border: 1px solid $border;
                position: absolute;
                left: -35px;
                top: 50%;
                -webkit-transform: translateY(-35px);
                background: $white-color;

                &.owl-next {
                    left: auto;
                    right: -35px;
                }
            }
        }
    }
}
/*---------------------
  Categories
-----------------------*/

.categories__item {
    background: linear-gradient(to top, rgba(162, 194, 207, 0.9), rgba(0, 0, 0, 0)) !important;
    height: 300px;
    position: relative;
    padding-top: 1px;
    //background-color: rgba(127, 173, 57, 1);

    img {
        width: 60% !important;
        margin: 50px auto 0;
    }

    h5 {
        position: absolute;
        left: 0;
        width: 100%;
        padding: 0 20px;
        bottom: 20px;
        text-align: center;

        a {
            font-size: 18px;
            color: $normal-color;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 2px;
            padding: 12px 0 10px;
            background: $white-color;
            display: block;
        }
    }
}

.categories__slider {

    .col-lg-3 {
        max-width: 100%;
    }

    &.owl-carousel {

        .owl-nav {

            button {
                font-size: 18px;
                color: $normal-color;
                height: 70px;
                width: 30px;
                line-height: 70px;
                text-align: center;
                border: 1px solid $border;
                position: absolute;
                left: -35px;
                top: 50%;
                -webkit-transform: translateY(-35px);
                background: $white-color;

                &.owl-next {
                    left: auto;
                    right: -35px;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .categories {
        padding-top: 100px;
    }
}
/*---------------------
  Featured
-----------------------*/
.featured {
    padding-top: 80px;
    padding-bottom: 40px;
}

.featured__controls {
    text-align: center;
    margin-bottom: 50px;

    ul {

        li {
            list-style: none;
            font-size: 18px;
            color: $normal-color;
            display: inline-block;
            margin-right: 25px;
            position: relative;
            cursor: pointer;

            &.active {

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 100%;
                height: 2px;
                background: $primary-color;
                content: '';
                opacity: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.featured__item {
    margin-bottom: 50px;

    &:hover {

        .featured__item__pic {

            .featured__item__pic__hover {
                bottom: 20px;
            }
        }
    }
}

.featured__item__pic {
    height: 270px;
    position: relative;
    overflow: hidden;
    background-position: center center;

    .product__code {
        background-color: #02173c;
        position: absolute;
        left: 0;
        top: 5px;
        padding: 0 12px;
        h5 {
            font-size: 16px;
            color: #fff;
            font-weight: 600;
            line-height: 33px;
            text-align: center;
        }
    }
}

.featured__item__pic__hover {
    position: absolute;
    left: 0;
    bottom: -50px;
    width: 100%;
    text-align: center;
    @include transition(all, .5s);

    li {
        list-style: none;
        display: inline-block;
        margin-right: 6px;

        &:last-child {
            margin-right: 0;
        }

        &:hover {

            a {
                background: $primary-color;
                border-color: $primary-color;

                i {
                    color: $white-color;
                    transform: rotate(360deg);
                }
            }
        }

        a {
            font-size: 16px;
            color: $normal-color;
            height: 40px;
            width: 40px;
            line-height: 40px;
            text-align: center;
            border: 1px solid $border;
            background: $white-color;
            display: block;
            border-radius: 50%;
            @include transition(all, .5s);

            i {
                position: relative;
                transform: rotate(0);
                @include transition(all, .3s);
            }
        }
    }
}

.featured__item__text {
    text-align: center;
    padding-top: 15px;
    border: 1px solid #ebebeb;

    h6 {
        margin-bottom: 10px;

        a {
            color: $heading-color-2;
        }
    }

    h5 {
        color: $heading-color-2;
        font-weight: 600;
    }

    span {
        font-size: 14px;
        color: #b2b2b2;
        display: block;
        margin-bottom: 4px;
    }

    textarea {
        display: flex;
        flex: auto;
        border: 1px solid #dee2e6;
        margin: 10px;
        width: 100%;
    }
}
/*---------------------
        Banner
-----------------------*/
.banner {
    &__pic {
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 50%;
        height: 200px;
    }
}
/*---------------------
  Latest Product
-----------------------*/
.latest-product {
    padding-top: 80px;
    padding-bottom: 0;
}

.latest-product__text {

    h4 {
        font-weight: 600;
        color: $normal-color;
        margin-bottom: 45px;
    }
}

.latest-product__slider {

    &.owl-carousel {

        .owl-nav {
            position: absolute;
            right: 20px;
            top: -75px;

            button {
                height: 30px;
                width: 30px;
                background: #F3F6FA;
                border: 1px solid #e6e6e6;
                font-size: 14px;
                color: #636363;
                margin-right: 10px;
                line-height: 30px;
                text-align: center;

                span {
                    font-weight: 600;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.latest-product__item {
    margin-bottom: 20px;
    overflow: hidden;
    display: block;
}

.latest-product__item__pic {
    float: left;
    margin-right: 26px;
    max-width: 50%;

    img {
        height: 110px;
        width: 110px;
    }
}

.latest-product__item__text {
    overflow: hidden;
    padding-top: 10px;

    h6 {
        color: $heading-color-2;
        margin-bottom: 8px;
    }

    span {
        font-size: 18px;
        display: block;
        color: $heading-color-2;
        font-weight: 600;
    }
}

/*---------------------
  Form BLog
-----------------------*/
.from-blog {
    padding-top: 50px;
    padding-bottom: 50px;

    .blog__item {
        margin-bottom: 30px;
    }
}

.from-blog__title {
    margin-bottom: 70px;
}